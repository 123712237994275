<template>
  <router-view />
  <el-config-provider :locale="locale"> </el-config-provider>
</template>

<script lang="ts">
import { ref } from 'vue';
import { defineComponent, nextTick, onMounted } from 'vue';
import { useStore } from 'vuex';
import { Mutations, Actions } from '@/store/enums/StoreEnums';
import { themeMode } from '@/core/helpers/config';
import { initializeComponents } from '@/core/plugins/keenthemes';
import tr from 'element-plus/dist/locale/tr.mjs';

export default defineComponent({
  name: 'app',
  setup() {
    const store = useStore();

    var locale = tr;

    onMounted(() => {
      /**
       * Overrides the layout config using saved data from localStorage
       * remove this to use static config (@/core/config/DefaultLayoutConfig.ts)
       */
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);

      /**
       *  Sets a mode from configuration
       */
      store.dispatch(Actions.SET_THEME_MODE_ACTION, themeMode.value);

      nextTick(() => {
        initializeComponents();

        // store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading');
        // store.dispatch(Actions.ADD_BODY_LOADING);
      });
    });

    return {
      locale,
    };
  },
});
</script>

<style lang="scss">
@import '~bootstrap-icons/font/bootstrap-icons.css';
@import '~apexcharts/dist/apexcharts.css';
@import '~quill/dist/quill.snow.css';
@import '~animate.css';
@import '~sweetalert2/dist/sweetalert2.css';
@import '~nouislider/distribute/nouislider.css';
@import '~@fortawesome/fontawesome-free/css/all.min.css';
@import '~socicon/css/socicon.css';
@import '~line-awesome/dist/line-awesome/css/line-awesome.css';
@import '~dropzone/dist/dropzone.css';
@import '~@vueform/multiselect/themes/default.css';
@import '~prism-themes/themes/prism-shades-of-purple.css';
@import '~element-plus/dist/index.css';

// Main demo style scss
@import 'assets/sass/plugins';
@import 'assets/sass/style';

//RTL version styles
//@import "assets/css/style.rtl.css";

#app {
  display: contents;
}

.el-select {
  width: 100%;
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}

.swal2-container {
  z-index: 20000 !important;
}

.tableHeader {
  color: black;
  font-size: 15px;
  border-bottom: 2px solid black;
}

.sort-caret {
  color: red;
}

.tableClass {
  border: 2px solid var(--el-border-color);
  width: 100%;
  font-size: 13px;
  color: black;
  width: 100%;
}

.customLoading {
  background: rgba(255, 255, 255, 0.9);
}

.customLoading .el-loading-text {
  font-size: 15px;
}

.customLoading .element-loading-spinner {
  color: rgba(122, 122, 122, 0.8);
}
</style>
