enum Actions {
  // action types
  GET_COMPANY = "getCompany",

  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  REMOVE_BODY_LOADING = "removeBodyLoading",
  ADD_BODY_LOADING = "addBodyLoading",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  VERIFY_ADMIN = "verifyAdmin",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",

  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  SET_THEME_MODE_ACTION = "setThemeModeAction",
  GET_SECTOR_LIST = "getsectorlist",
  GET_CONTRACTCODE_LIST = "getContractCodelist",
  GET_OFFERSUBJECTCODE_LIST = "getOfferSubjectCodelist",
  GET_CITY_LIST = "getCitylist",
  GET_GROUP_LIST = "getGrouplist",
  GET_DISTRICT_LIST = "getDistrictlist",
  GET_QUERTER_LIST = "getQuerterlist",
  ADD_CUSTOMER = "addCustomer",
  UPDATE_CUSTOMER = "updateCustomer",
  DELETE_CUSTOMER = "deleteCustomer",
  ADD_ADDRESS = "addAddress",
  UPDATE_ADDRESS = "updateAddress",
  DELETE_ADDRESS = "deleteAddress",
  GET_ADDRESSLISTBYCUSTOMERID = "getAddressListByCustomerId",
  GET_ADDRESSBYID = "getAddressById",
  GET_CUSTOMER = "getCustomer",
  GET_LASTTRADED_CUSTOMER = "getLastTradedCustomer",
  GET_CUSTOMER_BY_FILTER = "getCustomerByFilter",
  ADD_DEVICE = "addDevice",
  UPDATE_DEVICE = "updateDevice",
  UPDATE_DEVICETRANSFER = "updateDeviceTransfer",
  GET_DEVICE_BY_FILTER = "getDeviceByFilter",
  GET_DEVICE_BY_ID = "getDeviceById",
  GET_DEVICELIST_BY_ADDRESSID = "getDeviceListByAddressId",
  GET_DEVICE_BY_SERIALNO_FILTER = "getDeviceBySerialNoFilter",
  GET_DEVICE_BY_MODEL_NAME_FILTER = "getDeviceByModelNameFilter",
  GET_MAIN_PAGE_CUSTOMER = "getMainPageCustomer",

  GET_BOOTCODE_LIST = "getBootCodelist",
  GET_DETECTIONCODE_LIST = "getDetectionCodelist",
  GET_RESULTCODE_LIST = "getResultCodelist",

  GET_TECHNICIAN_LIST = "getTechnicianlist",
  GET_USER_LIST = "getUserList",
  GET_USER = "getUser",
  ADD_USER = "addUser",
  DELETE_USER = "deleteUser",
  UPDATE_USER = "updateUser",
  CHANGE_USER_PASSWORD = "changeUserPasword",

  ADD_DEVICESERVICE = "addDeviceService",
  ADD_BAKIMFORMUDEVICESERVICE = "addBakimFormuDeviceService",
  GET_RECEIVEDDEVICESERVICE = "getReceivedDeviceService",
  GET_OPENDEVICESERVICE = "getOpenDeviceService",
  GET_KAPATILMISDEVICESERVICE = "getKapatilmisService",
  GET_PARTSEXCHANGEDEVICESERVICELIST = "getPartsExchangeDeviceServiceList",
  GET_DEVICESERVICEWITHID = "getDeviceServiceWithId",
  GET_TECHNICIANASSINEDDEVICESERVICE = "getTechnicianAssignedDeviceService",
  GET_CLOSEDDEVICESERVICE = "getClosedDeviceService",
  GET_TOBEOFFEREDDEVICESERVICE = "getToBeOfferedDeviceService",
  GET_SENTOFFEREDDEVICESERVICE = "getSentOfferedDeviceService",
  UPDATE_ASSIGNTECHNICIANDEVICESERVICE = "updateAssignTechnicianDeviceService",
  UPDATE_RETURNDEVICESERVICETOTECHNICIAN = "updateReturnDeviceServiceTechnician",
  UPDATE_CLOSEDEVICESERVICE = "updateCloseDeviceService",
  UPDATE_TAKEDEVICESERVICEBACK = "updateTakeDeviceServiceBack",
  UPDATE_CANCELDEVICESERVICE = "updateCancelDeviceService",
  UPDATE_SENDDEVICESERVICETOCLOSE = "updateSendDeviceServiceToClose",
  DELETE_DELETEDEVICESERVICE = "deleteDeleteDeviceService",
  UPDATE_APPROVEDEVICESERVICEOFFER = "updateApproveDeviceServiceOffer",
  GET_TECHNICIANDEVICESERVICEREPORT = "getTechnicianDeviceServiceReport",

  ADD_DEVICESERVICEPART = "addDeviceServicePart",
  UPDATE_DEVICESERVICEPART = "updateDeviceServicePart",
  DELETE_DEVICESERVICEPART = "deleteDeviceServicePart",
  GET_DEVICESERVICEPARTLIST = "getDeviceServicePartList",
  GET_DEVICESERVICEPART = "getDeviceServicePart",

  GET_OFFERNOTSEND = "getOfferNotSend",
  GET_ORDERRECEIPT = "getOrderReceipt",
  ADD_OFFER = "addOffer",
  SEND_ORDERRECEIPT = "sendOrderReceipt",
  UPDATE_OFFER = "updateOffer",
  DELETE_OFFER = "deleteOffer",
  UPDATE_SENDOFFER = "updateSendOffer",
  UPDATE_SENDOFFERAGAIN = "updateSendOfferAgain",
  GET_OFFERREPORT = "getOfferReport",

  GET_DEVICETYPELIST = "getDeviceTypeList",
  GET_DEVICEBRANDLIST = "getDeviceBrandList",
  GET_DEVICEMODELBYBRANDIDLIST = "getDeviceModelByBrandList",
  GET_DEVICEMODELLIST = "getDeviceModelList",

  ADD_CONTRACT = "addContract",
  UPDATE_CONTRACT = "updateContract",
  DELETE_CONTRACT = "deleteContract",
  GET_CONTRACTLIST = "getContractList",
  GET_CONTRACTBYID = "getContractById",

  GET_MODEL_BY_NAME_FILTER = "getModelByNameFilter",

  GET_SOZLESMEBASIM = "getSozlesmeBasim",
  GET_MUSTERIRAPOR = "getMusteriRapor",
  GET_MUSTERIRAPORFILEASPDF = "getMusteriRaporFileAsPdf",
  GET_MUSTERIRAPORFILEASEXCEL = "getMusteriRaporFileAsExcel",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SIGNOUT = "signout",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG_PROPERTY = "setLayoutConfigProperty",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_THEME_MODE_MUTATION = "setThemeModeMutation",
}

export { Actions, Mutations };
